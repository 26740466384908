<!--Main Navigation-->
<div>
  <!-- Sidebar -->
  <nav id="sidebarMenu" class="collapse d-lg-block sidebar collapse bg-white">
    <div class="position-sticky">
      <div class="list-group list-group-flush mx-3 mt-4">
        <a routerLink="network" class="list-group-item list-group-item-action py-2 ripple" aria-current="true" [class.active]="router.url.includes('network')"><i class="fas fa-tachometer-alt fa-fw me-3"></i><span>Network</span></a>
        <a routerLink="mail" class="list-group-item list-group-item-action py-2 ripple" [class.active]="router.url.includes('mail')"><i class="fas fa-chart-area fa-fw me-3"></i><span>Contract</span></a>
      </div>
    </div>
  </nav>
  <!-- Sidebar -->
  <!-- Navbar -->
  <nav id="main-navbar" class="navbar navbar-expand-lg navbar-light bg-white fixed-top nav-box">
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <button class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation"><i class="fas fa-bars"></i></button>
      <!-- Brand -->
      <a class="navbar-brand" href="#"><img src="./../../../../assets/img/logo/logo_transparent_background.png" height="25" alt=""/><span class="text-black"></span></a>
      <!-- Right links -->
      <ul class="navbar-nav ms-auto d-flex flex-row">
        <!-- Notification dropdown -->
       <!-- <li class="nav-item dropdown">
          <a class="nav-link me-3 me-lg-0 dropdown-toggle hidden-arrow" href="javascript:void(0)" id="navbarDropdownMenuLink" role="button" data-mdb-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-bell"></i>
            <span class="badge rounded-pill badge-notification bg-danger">1</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="javascript:void(0)">Some news</a></li>
            <li><a class="dropdown-item" href="javascript:void(0)">Another news</a></li>
            <li><a class="dropdown-item" href="javascript:void(0)">Something else here</a></li>
          </ul>
        </li>-->

        <!-- Avatar -->
        <i class="fas fa-sign-out-alt text-danger"></i>
        <a (click)="logout()" href="javascript:void(0)"> Logout</a>
      </ul>
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->
</div>
<!--Main Navigation-->

<!--Main layout-->
<main style="margin-top: 58px">
  <router-outlet></router-outlet>
</main>
<!--Main layout-->
