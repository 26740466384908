import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './_helpers';
import {HomePageComponent} from "./home-page/home-page.component";
import {LoginComponent} from "./layouts/admin/login";
import {LayoutComponent} from "./layouts/admin/layout/layout.component";
import {NgModule} from "@angular/core";
import {NetworkComponent} from "./layouts/admin/network/network.component";
import {MailComponent} from "./layouts/admin/mail/mail.component";

const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'login', component: LoginComponent},
  // {path: 'admin', component: LayoutComponent, loadChildren: () => import('./layouts/admin/layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard] },
  // {path: 'admin',loadChildren:  './layouts/admin/layout/layout.module#LayoutModule', canActivate: [AuthGuard] },
  { //For relase 1
    path: '',
    component: LayoutComponent,
    children: [
      {path: 'admin', component: NetworkComponent},
      {path: 'network', component: NetworkComponent},
      {path: 'mail', component: MailComponent}
    ]
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
